
import React from 'react';
import Header from './Components/Header';
import Categories from './Components/Categories';
 import Restaurants from './Components/Restaurants';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import TermsConditions from './Components/TermsConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ContactUs from './Components/ContactUs';
import CancellationPolicy from './Components/CancellationPolicy';
import ShippingPolicy from './Components/ShippingPolicy';


const categories = [
  { name: 'Burgers', link: '/category/burgers' },
  { name: 'Pizza', link: '/category/pizza' },
  { name: 'Chinese', link: '/category/chinese' },
  { name: 'Indian', link: '/category/indian' },
];

function App() {
  return (
    <div className="App">
     
      <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<Categories categories={Categories} />} />
        <Route path="/restaurants" element={<Restaurants restaurants={Restaurants} />} />
        <Route path="/termsconditions" element={<TermsConditions/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path="/contactus" element={<ContactUs/>}/>
        <Route path="/cancellationpolicy" element={<CancellationPolicy/>}/>
        <Route path="/shippingpolicy" element={<ShippingPolicy/>}/>
        </Routes>
        <Footer />
      </Router>
     
    </div>
  );
}

export default App;
