import React from 'react'

const ContactUs = () => {
    return (
        <div className="contact-us-container">
            <h1><b>Contact us</b></h1>
            <p>You may contact us using the information below:</p>
            <p>Merchant Legal entity name: DINE DEAL TECH LLP
                Registered Address: H.NO.1225,URALI DEVACHI,HAVELI,Uruli Devachi,URALI DEVACHI POLICE STATION, Haveli Pune MAHARASHTRA 412308
                Operational Address: H.NO.1225,URALI DEVACHI,HAVELI,Uruli Devachi,URALI DEVACHI POLICE STATION, Haveli Pune MAHARASHTRA 412308
                Telephone No: 9673307070
                E-Mail ID: dinedealtech@gmail.com</p>
            <p><i>Disclaimer: The above content is created at DINE DEAL TECH LLP's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.</i></p>
        </div>
    )
}

export default ContactUs
