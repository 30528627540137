
import React from 'react';
import './Styles.css';
import { NavLink, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate=useNavigate()

  const mix2=(Route)=>{

    if(Route == 1){
      navigate('privacypolicy');
    }else if(Route == 2){
      navigate('termsconditions');
    }else if(Route == 3){
      navigate('contactus');
    }else if(Route == 4){
      navigate('cancellationpolicy');
    }else {
      navigate('shippingpolicy');
    }
    
  }
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          {/* Map */}
          <div className="footer-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.712376004249!2d73.87536457508836!3d18.45974498305481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebe91b5a5e3d%3A0xe1c0f80a606b3e83!2sPune%2C%20Maharashtra%20412308%2C%20India!5e0!3m2!1sen!2sus!4v1692713128044!5m2!1sen!2sus"
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Location Map"
            ></iframe>
          </div>
          
         
          <div className="footer-address">
            <p>H. No. 1225, URALI DEVACHI, HAVELI, Uruli Devachi, URALI DEVACHI POLICE STATION, Haveli, Pune-412308, Maharashtra, India</p>
          <p className=' text-start'> <span>E-Mail ID :</span> dinedealtech@gmail.com</p>
          <p className=' text-start'> <span>E-Mail ID :</span> support@dinedeal.in</p>
           
          </div>
        </div>
       
        <div className="footer-nav">
          <a  onClick={() => mix2(1)} style={{cursor:"pointer"}}>Privacy Policy</a>
          <a  onClick={() => mix2(2)} style={{cursor:"pointer"}}>Terms and Conditions</a>
          <a  onClick={() => mix2(3)} style={{cursor:"pointer"}}>Contact Us</a>
          <a  onClick={() => mix2(4)} style={{cursor:"pointer"}}>Cancellation & Refund Policy</a>
          <a  onClick={() => mix2(5)} style={{cursor:"pointer"}}>Shipping Policy</a>
        </div>
        
        <div className="footer-copyright">
          <p>&copy; 2024 Dine Deal Tech LLP. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
